export const TwitterIcon = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    >
      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
    </svg>
  )
}

export const CivicIcon = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fff">
        <path d="m11.9787 8.49691c0-1.11423-.9003-2.01742-2.01096-2.01742-1.11059 0-2.01095.90318-2.01095 2.01742 0 .77964.44156 1.45471 1.08685 1.79059l-.74529 3.2332h3.33875l-.7452-3.2332c.6452-.33588 1.0868-1.01095 1.0868-1.79059z" />
        <path d="m17.8334 12.5426c-.3906 3.0422-2.987 5.401-6.124 5.401h-3.48298c-3.40585 0-6.1767-2.7798-6.1767-6.1966v-3.49404c0-3.4168 2.77085-6.19658 6.1767-6.19658h3.48298c3.137 0 5.7333 2.35889 6.124 5.40107h2.0634c-.4003-4.1788-3.9189-7.45745-8.1874-7.45745h-3.48298c-4.53607 0-8.22642 3.7023-8.22642 8.25296v3.49404c0 4.5508 3.69036 8.253 8.22642 8.253h3.48298c4.2685 0 7.7871-3.2787 8.1874-7.4574z" />
      </g>
    </svg>
  )
}
