import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

export function RealmsLogo(props: Props) {
  return (
    <svg
      {...props}
      viewBox="0 0 96 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.578 21.142C44.408 21.142 43.4 20.404 41.942 17.722L41.564 17.02C43.742 16.894 45.02 15.49 45.02 13.528C45.02 11.314 43.508 10 40.898 10H35.75V22.51H37.28V17.038H39.854L40.592 18.388C42.302 21.628 43.49 22.546 45.524 22.546C45.668 22.546 45.794 22.528 45.92 22.51V21.088C45.83 21.124 45.722 21.142 45.578 21.142ZM40.898 11.35C42.608 11.35 43.436 12.124 43.436 13.528C43.436 14.896 42.626 15.688 40.898 15.688H37.28V11.35H40.898Z"
        fill="#201F27"
      />
      <path
        d="M54.4684 19.522C53.8744 20.71 53.0644 21.43 51.6784 21.43C49.9684 21.43 48.9244 20.314 48.8344 18.532H55.5124C55.5304 18.424 55.5304 18.208 55.5304 18.046C55.5304 15.418 54.0184 13.654 51.5344 13.654C49.0864 13.654 47.2684 15.508 47.2684 18.19C47.2684 20.854 49.1404 22.726 51.6784 22.726C53.7304 22.726 54.9364 21.592 55.6204 20.242L54.4684 19.522ZM51.5164 14.932C53.0284 14.932 53.8564 15.778 53.9824 17.326H48.8704C49.0684 15.832 50.0764 14.932 51.5164 14.932Z"
        fill="#201F27"
      />
      <path
        d="M60.979 22.726C61.987 22.726 62.959 22.258 63.589 21.394V22.51H65.083V17.092C65.083 14.86 63.643 13.654 61.573 13.654C59.593 13.654 58.171 14.788 57.721 16.624L59.071 17.002C59.395 15.652 60.151 14.95 61.555 14.95C62.833 14.95 63.589 15.778 63.589 17.146V18.28C63.031 17.758 62.149 17.254 60.979 17.254C59.053 17.254 57.667 18.478 57.667 19.99C57.667 21.682 59.053 22.726 60.979 22.726ZM61.123 21.484C59.989 21.484 59.251 20.872 59.251 19.99C59.251 19.126 59.989 18.514 61.123 18.514C62.167 18.514 63.049 19.09 63.589 19.72V20.044C63.013 20.872 62.167 21.484 61.123 21.484Z"
        fill="#201F27"
      />
      <path d="M69.6409 22.51V10H68.1469V22.51H69.6409Z" fill="#201F27" />
      <path
        d="M82.8761 13.654C81.5981 13.654 80.4821 14.068 79.7261 15.202C79.2401 14.14 78.2501 13.654 76.9721 13.654C75.8201 13.654 74.8481 14.122 74.2541 14.95V13.87H72.7601V22.51H74.2541V17.29C74.2541 15.778 75.2981 14.95 76.6301 14.95C77.9621 14.95 78.6641 15.778 78.6641 17.308V22.51H80.1581V17.308C80.1581 15.778 81.2021 14.95 82.5341 14.95C83.8661 14.95 84.5861 15.778 84.5861 17.308V22.51H86.0801V17.164C86.0801 15.004 84.9281 13.654 82.8761 13.654Z"
        fill="#201F27"
      />
      <path
        d="M92.4092 22.726C94.3892 22.726 95.8832 21.79 95.8832 20.08C95.8832 18.298 94.5152 17.83 93.0752 17.578L92.0492 17.398C90.8432 17.2 90.3932 16.894 90.3932 16.12C90.3932 15.346 91.0952 14.896 92.2832 14.896C93.6692 14.896 94.2992 15.688 94.4792 16.516L95.8292 16.156C95.5772 14.734 94.4612 13.654 92.3372 13.654C90.2312 13.654 88.9352 14.626 88.9352 16.138C88.9352 17.992 90.3392 18.46 91.8152 18.73L92.8232 18.892C93.9212 19.09 94.3352 19.36 94.3352 20.098C94.3352 21.034 93.5252 21.484 92.4452 21.484C91.0772 21.484 90.0332 20.962 89.8892 19.414L88.5032 19.666C88.5392 21.772 90.3392 22.726 92.4092 22.726Z"
        fill="#201F27"
      />
      <path
        d="M21.6658 28.1373C21.5204 28.1932 21.4034 28.0154 21.5064 27.8985C24.3031 24.7263 25.9999 20.5612 25.9999 15.9997C25.9999 11.4383 24.3031 7.27314 21.5064 4.10096C21.4034 3.98409 21.5203 3.80623 21.6658 3.86217C26.5405 5.7373 29.9999 10.4645 29.9999 15.9997C29.9999 21.5349 26.5405 26.2622 21.6658 28.1373Z"
        fill="#00C2FF"
      />
      <path
        d="M22.7389 8.35852C22.8039 8.50619 22.5978 8.65459 22.4674 8.55961C21.8598 8.11704 20.7827 7.5 19.5 7.5C17 7.5 13.5 10.5 13.5 16C13.5 21.5 16 24.5 19 24.5C20.6159 24.5 21.8139 23.8734 22.4593 23.4301C22.5923 23.3388 22.8039 23.4938 22.7389 23.6415C21.1225 27.3165 17.8157 30 14 30C7.92487 30 2 23.732 2 16C2 8.26801 7.92487 2 14 2C17.8157 2 21.1225 4.68351 22.7389 8.35852Z"
        fill="url(#paint0_radial_313_8960)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_313_8960"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19 16) rotate(180) scale(15.1067 20.1422)"
        >
          <stop offset="0.156146" stopColor="#006585" />
          <stop offset="1" stopColor="#00C2FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}
