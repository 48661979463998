import type { AppProps } from 'next/app'
import '@dialectlabs/react-ui/index.css'

import { App as BaseApp } from '@components/App'
import { App as HubApp } from '@hub/App'

import '../styles/index.css'
import '../styles/typography.css'
import '@hub/components/controls/RichTextEditor/index.css'

import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: '0cb14049-b0b5-4f29-b40d-734140cfb52a',
  clientToken: 'pubbf9ba9297b39130fc29e1fe43c1b9935',
  site: 'datadoghq.com',
  service: 'dao.metaplex.com',
  env: process.env.NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 0,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

datadogRum.startSessionReplayRecording()

export default function App({ Component, pageProps, router }: AppProps) {
  if (router.pathname.startsWith('/realm/[id]')) {
    return (
      <HubApp>
        <Component {...pageProps} />
      </HubApp>
    )
  }

  return (
    <BaseApp>
      <Component {...pageProps} />
    </BaseApp>
  )
}
